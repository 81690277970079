// import Button from '@mui/material/Button'

function App() {

  return (
    <div>
      <section
        id="Welcome"
        style={{
          padding: '40px'
        }}
      >
        <h1
          style={{
            fontSize: '85px',
            maxWidth: '14ch',
            marginBottom: '40px',
            marginTop: 0,
            lineHeight: '1.1',
            fontWeight: 700,
            letterSpacing: '-2px'
          }}
        >
          Dorin
          <br />
          Manase
          <span style={{ fontFamily: 'Lato', color: '#33323099' }}>
            .
          </span>
        </h1>
        <h2
          style={{
            marginTop: 0,
            marginBottom: 40,
            color: '#33323099',
            fontSize: '23px',
            lineHeight: '1.4',
            fontWeight: 400,
            maxWidth: '1000px'
          }}
        >
          Currently with the Data Aggregation, Translation and Architecture
          (<a href="https://uhndata.io/" style={{ color: '#3269ff', fontWeight: 700, textDecoration: 'none' }} target="blank">DATA</a>) team within the University Health Network,
          working as a senior manager specializing in the use of advanced software development approaches to help clinicians streamline and improve patient care.
          <br/><br/>
          A Masters of Information student at the University of Toronto concentrating in User Experience Design.
          <br/><br/>
          And a soccer coach as part of the Tri-Campus League at the University of Toronto.
        </h2>
      </section>
    </div>
  )
}

export default App
